import { Subject } from 'rxjs';
import { EndpointGuardMessage } from '.';
import { PreventUnauthenticatedGuard } from './preventUnauthenticated';

export enum MutationGuardMessageType {
  Unauthenticated = 'unauthenticated',
  UnconfirmedEmail = 'emailNotConfirmed',
}

export const AuthGuardSubject$ = new Subject<
  EndpointGuardMessage<unknown, unknown>
>();

export const EmailNotConfirmedSubject$ = new Subject<
  EndpointGuardMessage<unknown, unknown>
>();

export const isAuthenticationError = (errorMessage: string) =>
  errorMessage === 'User is not authenticated';

export const isUnconfirmedEmailError = (errorMessage: string) =>
  errorMessage === 'Usuário não possui email confirmado';

/**
 * A Guard to request the user to authenticate (if it is unauthenticated).
 *
 * If the user is not authenticated or an authentication error happens,
 * then the message is redirected to AuthGuardSubject$ and returns true (to cancel the request).
 *
 * If an unconfirmed email happens
 * then the message is sent to EmailNotConfirmedSubject$ and returns true (to cancel the request).
 *
 * @param message Guard message from endpoint (EndpointGuardMessage)
 * @returns If the request can continue
 */
export function AuthGuard<Response, Variables>(
  message: EndpointGuardMessage<Response, Variables>
) {
  const userUnauthenticated = PreventUnauthenticatedGuard(message, false);
  if (userUnauthenticated) {
    AuthGuardSubject$.next(message);
    return true;
  }

  const isEmailNotConfirmedError =
    !message.loading && isUnconfirmedEmailError(message.error?.message || '');

  if (isEmailNotConfirmedError) {
    EmailNotConfirmedSubject$.next({
      ...message,
      retry: () => {},
    });
    return true;
  }

  const isAuthError =
    !message.loading && isAuthenticationError(message.error?.message || '');

  if (isAuthError) {
    AuthGuardSubject$.next(message);
    return true;
  }

  return false;
}
